<template>
  <div class="charge-wrap">
    <div class="tip fs24 fctheme">
      说明：1元等于{{ 1 / this.$config.pointRMBRate }}积分
    </div>
    <div class="charge-box dfr cross-center">
      <div class="left fc333">￥</div>
      <div class="right">
        <van-field
          class="price "
          @input="priceInput"
          v-model.trim="price"
          :adjust-position="false"
          clearable
          type="number"
          placeholder="请输入需要充值的金额"
        />
      </div>
    </div>
    <div class="bottom-charge dfc flex-center">
      <icy-button @click.native="charge">确认充值</icy-button>
      <icy-iphone></icy-iphone>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      price: ""
    };
  },
  created() {
    this.$title("充值积分");
  },
  mounted() {},
  methods: {
    priceInput() {},
    async charge() {
      const _this = this;
      if (!this.price) {
        return;
      }
      const resp = await this.$API
        .post("charge", { price: this.price })
        .then(resp => resp);
      if (resp.code == 1000) {
        let config = resp.data;
        window.WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: config["appId"], //公众号ID，由商户传入
            timeStamp: config["timeStamp"],
            nonceStr: config["nonceStr"],
            package: config["package"],
            signType: config["signType"],
            paySign: config["paySign"] // 支付签名
          },
          function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              _this.$toast.success("支付成功");
              _this.$goReplace(`myMeeting`, { type: 1 });
              _this.price = "";
              _this.$goReplace("me");
              _this.$toast.success("充值成功");
            }
          }
        );
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.charge-wrap {
  width: 7.5rem;
  min-height: 100vh;
  background: #f7f7f7;
  position: relative;
  .tip {
    padding: 0.14rem 0.36rem;
    height: 0.6rem;
  }
  .charge-box {
    height: 1.6rem;
    background: #fff;
    padding: 0 0.36rem;
  }
  .bottom-charge {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // margin-top: calc(100vh - 1.6rem - 0.6rem - 1.2rem);
    padding: 0.2rem 0 0.2rem;
  }
  .left {
    font-size: 0.48rem;
  }
  .right {
    color: #333;
    font-size: 0.8rem;
  }
}
</style>
